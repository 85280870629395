
import { defineComponent } from 'vue'
import PageContentSidebarList from '@/components/layout/pageContent/sidebar/PageContentSidebarList.vue'
import PageContentSidebarListTitle from '@/components/layout/pageContent/sidebar/sidebarItem/PageContentSidebarListTitle.vue'
import { tasksSettingsSidebarRoutes, tasksSettingsSidebarAllRoute, tasksSettingsSidebarArchiveRoute } from '@/router/sidebarRoutes/tasksRoutes'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    PageContentSidebarList,
    PageContentSidebarListTitle,
  },
  setup() {
    const { isEmptyHalfMode, isEmptyMode } = useModes()

    return {
      isEmptyHalfMode,
      isEmptyMode,
      tasksSettingsSidebarRoutes,
      tasksSettingsSidebarAllRoute,
      tasksSettingsSidebarArchiveRoute,
    }
  },
})
