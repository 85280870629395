import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "contact-sidebar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_content_sidebar_list = _resolveComponent("page-content-sidebar-list")!
  const _component_page_content_sidebar_list_title = _resolveComponent("page-content-sidebar-list-title")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_page_content_sidebar_list, { items: _ctx.tasksSettingsSidebarAllRoute }, null, 8, ["items"]),
    (!_ctx.isEmptyHalfMode && !_ctx.isEmptyMode)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_page_content_sidebar_list_title, {
            title: "active boards",
            class: "mt-3"
          }),
          _createVNode(_component_page_content_sidebar_list, { items: _ctx.tasksSettingsSidebarRoutes }, null, 8, ["items"])
        ], 64))
      : _createCommentVNode("", true),
    (!_ctx.isEmptyMode)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_page_content_sidebar_list_title, {
            title: "archive",
            class: "mt-3"
          }),
          _createVNode(_component_page_content_sidebar_list, { items: _ctx.tasksSettingsSidebarArchiveRoute }, null, 8, ["items"])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}