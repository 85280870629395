
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TasksSettingsSidebar from '@/components/pages/tasks/settings/TasksSettingsSidebar.vue'

export default defineComponent({
  components: {
    TasksSettingsSidebar,
    TmButton,
  },
})
